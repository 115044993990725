<template>
  <div>

    <p v-html="page.inleiding"></p>

    <div
      class="uitklapvraag"
      v-for="(u, index) in uitklapvragen"
      :class="{ invalid: u.valid === false }"
      :key="index"
    >
      <div class="categorie" @click="klapUit(u)">
        <h3>
          <v-icon v-if="u.reactie === true">mdi-check</v-icon>
        {{ u.omschrijving }}
        </h3>
      </div>

      <div v-if="u.uitgeklapt">
        <p class="text--primary ml-2" v-html="u.toelichting"></p>

        <v-checkbox
          class="ml-2"
          :label="`Rol '${u.omschrijving}' is van toepassing op mijn werkzaamheden`"
          v-model="u.reactie"
          @change="wijzigCheckbox($event, u)"
        ></v-checkbox>

        <div class="ml-4 mr-4 mb-4" flat v-if="u.reactie == true">
          <p>Beantwoord de volgende vragen met <b>Ja</b> of <b>Nee</b>. Als u twijfelt omdat u denkt dat u niet genoeg kennis heeft over het onderwerp, antwoord dan met <b>Nee</b>.</p>
          <v-form ref="form" v-model="u.valid">
            <div v-for="(v, index) in formModel" :key="index">
              <v-layout pa-2 class="question" wrap v-if="v.soort === 'text'">
                <v-flex xs12>{{ v.omschrijving }}</v-flex>
                <v-flex xs12>
                  <v-text-field :rules="rules" v-model="v.reactie" required>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout
                pa-2
                class="question"
                mt-5
                wrap
                v-if="v.soort === 'select'"
              >
                <v-flex xs12 sm8>{{ v.omschrijving }}</v-flex>
                <v-flex xs12 pl-3 sm4>
                  <v-select
                    :value="v.reactie"
                    :items="enums.filter((f) => f.soort === v.items)"
                    :rules="[(v) => !!v || 'Waarde is verplicht']"
                    @change="answerChanged($event, v)"
                    required
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "UitklapForm",
  props: ["page"],
  data: () => ({
    formModel: [],
    uitklapvragen: [],
    rules: [(v) => !!v || "Waarde is verplicht"],
  }),
  computed: {
    ...mapGetters({
      vragenlijst: "vragenlijst",
    }),

    ...mapState({
      enums: "enums",
    }),
    pageValid() {
      var ids = new Set(
        this.uitklapvragen.filter((f) => f.reactie == true).map((m) => m.id)
      );

      return (
        this.vragenlijst.filter((f) => ids.has(f.vraag_id) && f.reactie == null)
          .length == 0
      );
    },
  },
  methods: {
    ...mapActions(["updateAntwoorden"]),

    answerChanged(antwoord, v) {
      let res = [];

      res.push({ id: v.id, reactie: antwoord });

      this.updateAntwoorden(res);
    },

    submit(u) {
      this.formModel.push(u);

      if (u.valid) {
        this.updateAntwoorden(this.formModel);
        this.formModel = [];
        this.$emit("onSucces");
      }
    },

    klapUit(u) {
      u.uitgeklapt = true;
      this.uitklapvragen.forEach((fe) => {
        if (fe.id != u.id) {
          fe.uitgeklapt = false;
        }
      });

      if (u.reactie == true) {
        this.vulSubvragen(u.id);
      }
    },

    wijzigCheckbox(antwoord, v) {
      let res = [];

      res.push({ id: v.id, reactie: antwoord });

      this.updateAntwoorden(res);

      if (antwoord == true) {
        this.vulSubvragen(v.id);
      } else {
        this.formModel = [];
        v.valid = true;
      }
    },

    vulSubvragen(vraagId) {
      this.formModel = [
        // gebruik map om een kopie te maken van de array
        ...this.vragenlijst
          .filter((f) => f.vraag_id == vraagId)
          .map((m) => ({ ...m })),
      ];
    },
  },
  watch: {
    pageValid: function (val) {
      this.$emit("pageValidChanged", val);
    },

    page: {
      immediate: true,
      handler(nv) {
        if (nv !== null) {
          this.uitklapvragen = this.vragenlijst
            .filter((f) => f.pagina_id == nv.id)
            .map((m) => {
              let valid = false;

              if (m.reactie === true) {
                if (
                  this.vragenlijst.filter(
                    (f) => f.vraag_id === m.id && f.reactie === null
                  ).length == 0
                ) {
                  valid = true;
                }
              } else {
                valid = true;
              }

              return {
                ...m,
                valid: valid,
                uitgeklapt: false,
              };
            });
        }
      },
    },
  },
};
</script>


<style scoped>
.uitklapvraag {
  padding-bottom: 5px;
  margin: 7px;
  border: solid 1px silver;
}
.categorie {
  padding: 5px;
  cursor: pointer;
}

.invalid {
  background-color: rgb(253, 248, 238);
  border: dashed 3px orange;
}

.valid {
  background-color: rgb(0, 255, 13);
}

.question {
  background-color: #f0f0f0;
}

p {
  color: black;
}  

</style>